import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';


export interface UxMultiFactorProps {
  logoUri?: string;
  clientName?: string;
  reason?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: '100px',
    width: '100px',
    marginBottom: theme.spacing(1)
  },
  logo: {
    height: '25px',
    width: '25px',
  },
  arrow: {
    fontSize: '40px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *:not(:last-child)': {
      marginRight: '10px'
    },
    '& > *:first-child': {
      marginRight: '5px'
    }
  },
  clientInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  border: {
    marginTop: theme.spacing(1),
    background: theme.palette.primary.light,
    height: '2px',
    width: '100%'
  },
  title: {
    fontSize: '14px'
  }
}));

export const InteractionHeader = (props: UxMultiFactorProps): JSX.Element | null => {
  const { logoUri, clientName } = props;
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <img src={`${process.env.PUBLIC_URL}/miauth-latest-logo.svg`} className={classes.icon} />
      <div className={classes.header}>
        {logoUri && <img src={logoUri} className={classes.logo} alt="logo" />}  
        <Typography className={classes.title}>{clientName}</Typography>
      </div>
    </div>
  );
};