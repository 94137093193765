/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog } from '../Dialog/Dialog';
import Typography from '@material-ui/core/Typography';

import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Alert from '@material-ui/lab/Alert';
import { ProgressButton } from '../../ProgressButton/ProgressButton';

import { InteractionHeader } from '../Header';

export interface UxCodeEntryProps {
  helperText?: string;
  title?: string;
  enabled: boolean;
  busy: boolean;
  onChange: (value: string) => void;
  onPressSubmitCode: () => void;
  onPressCancel: () => void;
  onResendCode: () => void;
  clientName?: string;
  logoUri?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: theme.typography.pxToRem(360),
    maxWidth: theme.typography.pxToRem(400),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.down(325)]: {
      margin: 0,
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(4)
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: '100%',
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 60px #0d4c70 inset !important',
        backgroundColor: '#0d4c70',
        boxShadow: `0 0 0 1000px #0d4c70 inset`,
        backgroundClip: 'content-box !important',
        borderRadius: 0,
      },
  },
  spacer: {
    marginTop: theme.spacing(1)
  },
  form: {
    alignText: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  resend: {
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    cursor: 'pointer'
  },
  cancelButton: {
    color: '#fff'
  }
}));

export const UxCodeEntry = (props: UxCodeEntryProps): JSX.Element => {
  const { helperText, onChange, onPressSubmitCode, onPressCancel, onResendCode, enabled, busy, clientName, logoUri } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<{}>) => {
    const target = e.target as HTMLInputElement;
    onChange(target.value);
  }, [onChange]);

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onPressSubmitCode();
  }, [onPressSubmitCode]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.container,
      }}
    >
      <InteractionHeader clientName={clientName} logoUri={logoUri} />
      <form onSubmit={handleKeyUp} className={classes.form}>
        <Typography id="alert-dialog-title">Enter your 6 digit Code</Typography>
          {/* <InputBase /> */}
          {helperText && (
            <Alert className={classes.alert} id="login-error-message" variant="filled" severity="error">
              {helperText}
            </Alert>
          )}
          <div className={classes.spacer} />
          <OutlinedInput
            className={classes.textField}
            autoFocus
            inputRef={inputRef}
            id="input-code"
            labelWidth={0}
            placeholder="Your OTP Code"
            inputProps={{
              maxLength: 6,
              type: 'tel',
              autoComplete: 'one-time-code',
              autoCapitalize: 'off',
              spellCheck: false,
              autoCorrect: 'off',
            }}
            onChange={handleChange}
            disabled={!enabled}
          />
          <div className={classes.spacer} />
          <div className={classes.resend} onClick={onResendCode}>Resend Code</div>
      </form>
      <div className={classes.buttonContainer}>
            <Button className={classes.cancelButton} color="primary" onClick={onPressCancel} disabled={!enabled}>
              Cancel
            </Button>

            <ProgressButton
              id="submit-btn"
              color="primary"
              onClick={onPressSubmitCode}
              disabled={!enabled}
              loading={busy}
              success={false}
            >
              Submit Code
            </ProgressButton>
          </div>
    </Dialog>
  );
};
