import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog } from '../Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { InteractionHeader } from '../Header';

export interface UxMultiFactorProps {
  title: string;
  onPressCancel: () => void;
  clientName?: string;
  logoUri?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: theme.typography.pxToRem(360),
    maxWidth: theme.typography.pxToRem(400),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.down(325)]: {
      margin: 0,
      padding: theme.spacing(1),
    },
  },
  cancelButton: {
    color: '#fff'
  },
  center: {
    textAlign: 'center',
  }
}));

export const UxHyprPush = (props: UxMultiFactorProps) => {
  const { title, onPressCancel, clientName, logoUri } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.container,
      }}
    >
      <InteractionHeader clientName={clientName} logoUri={logoUri} />
      <DialogTitle id="alert-dialog-title" className={classes.center}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={classes.center} >
          Push Notification has been sent. Please confirm the request in miAuth.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelButton} color="primary" onClick={onPressCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
