/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call } from 'redux-saga/effects';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const debug = require('debug')('eog-oidc-app:api:send');

export class SendError extends Error {
  status = 0;
  statusText = '';
  body = '';
}

export function* send(info: RequestInfo, init?: RequestInit) {
  debug(info, init);

  const response = yield call(fetch, info, init);
  debug(`response.ok = ${response.ok}, response.status = ${response.status}`);
  if (response.ok) {
    const json = yield call([response, 'json']);
    debug(`response.json`, json);

    console.log(response);

    const location = response.headers.get('Location');

    if (location) {
      json.location = location
    }

    return json;
  }

  const text = yield call([response, 'text']);
  debug('response.text', text);
  const error = new SendError(`send failed`);
  error.statusText = response.statusText;
  error.status = response.status;
  error.body = text;
  throw error;
}
