import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isExpired } from './selectors';
import { actions } from './reducer';
import { Loader } from './Loader';
import { Splash } from './Splash'

// SessionWall is THE SSO implementation
// SessionWall shows a loading indicator a new session is required
// It then dispatches an action to refetch a session
// If THAT comes back with a location header, it will redirect over there
// That will come back with the new session.

type Props = {
  children: React.ReactNode;
};

export const SessionWall: FC<Props> = props => {
  const sessionIsExpired = useSelector(isExpired);
  const dispatch = useDispatch();

  if (sessionIsExpired) {
    dispatch(actions.fetchMe());
    return <Splash />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
