import { Palette } from "@material-ui/core/styles/createPalette";
import { geodeColors } from "@eog/geode-colors";

export const darkTheme: Partial<Palette> = {
  type: "dark",
  primary: {
    main: "#1996D5",
    light: "#1996D5",
    dark: "#1996D5",

    contrastText: geodeColors.gray[5],
  },
  secondary: {
    main: "#1996D5",
    light: "#1996D5",
    dark: "#1996D5",
    contrastText: geodeColors.gray[5],
  },
  background: {
    default: "#ffffff",
    paper: "#ffffff",
  },
  text: {
    primary: geodeColors.gray[60],
    secondary: geodeColors.gray[60],
    disabled: geodeColors.gray[80],
    hint: geodeColors.gray[60],
  },
};
