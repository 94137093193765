/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../store/reducers';
import { Splash } from 'components/SessionWall/Splash'
import { useParams } from 'react-router';
import { FailedInit } from './FailedInit';
import { interactionSlice } from './slices';

export interface InteractionWallProps {
  children: React.ReactNode;
}

export function InteractionWall(props: InteractionWallProps): JSX.Element {
  const { interactionId } = useParams<any>();
  const { error, interaction } = useSelector((state: IState) => state.interaction);
  const dispatch = useDispatch();

  if (!interactionId) {
    throw new Error('interactionId is required');
  }

  useEffect(() => {
    const action = interactionSlice.actions.loadInteraction({ interactionId });
    dispatch(action);
  }, [interactionId, dispatch]);

  if (!error && !interaction) {
    return <Splash />;
  } else if (interaction) {
    return <>{props.children}</> || <Splash />;
  } else {
    return <FailedInit />;
  }
}
