import React, { FC } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

type LoaderProps = unknown;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'absolute',
    height: '100vh',
    width: '100vw',
  },
  icon: {
    height: '320px',
    width: '360px',
    marginBottom: '8px'
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw'
  },
  text: {
    color: '#ffffff'
  }
});

export const Splash: FC<LoaderProps> = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.container}>
        <img src={`${process.env.PUBLIC_URL}/miauth-pulse.svg`} className={classes.icon} />
        <Typography className={classes.text} variant="h5">Keeping EOG Secure</Typography>
      </div>
      <footer className={classes.footer}>
        <Typography className={classes.text}>&copy; 2023 EOG Resources, Inc. All Rights Reserved.</Typography>
      </footer>
    </>
  );
};