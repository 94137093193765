import React, { useCallback, ChangeEvent, memo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Typography from '@material-ui/core/Typography/Typography';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import clsx from 'clsx';
import { ProgressButton } from '../ProgressButton/ProgressButton';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  input: {
    // paddingLeft: 'inherited'
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingLeft: 'inherit',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  textField: {
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 60px #0d4c70 inset !important',
        backgroundColor: '#0d4c70',
        boxShadow: `0 0 0 1000px #0d4c70 inset`,
        backgroundClip: 'content-box !important',
        borderRadius: 0,
      },
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    alignSelf: 'center',
    textAlign: 'center',
    maxWidth: '300px',
  },
  button: {},
  buttonGroup: {
    lineHeight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    alignSelf: 'center',
  },
  errorMessage: {
    alignSelf: 'center',
    fontSize: '16px'
  },
  phone: {
    marginLeft: '12px'
  }
}));

export interface SMSEntryUxProps {
  errorMessage?: string;
  message: string;
  phoneNumber?: string;
  onPressSubmit: () => void;
  onChangeValue: (key: string, value: string) => void;
  loading: boolean;
  interactionId: string | null;
}

export const SMSEntryUx = memo((props: SMSEntryUxProps) => {
  const { onChangeValue, onPressSubmit, phoneNumber, message, errorMessage, loading, interactionId } = props;
  const classes = useStyles({});

  const handleChangeValue = useCallback(
    (value: any) => {
      onChangeValue('phoneNumber', value);
    },
    [onChangeValue]
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.title} dangerouslySetInnerHTML={{ __html: message }}></Typography>
      {errorMessage && (
        <FormHelperText className={classes.errorMessage} error>
          {errorMessage}
        </FormHelperText>
      )}

      <div className={classes.phone}>
        <PhoneInput
          country="us"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={handleChangeValue}
          onlyCountries={['us', 'mx', 'tt', 'vn', 'in', 'om']}
          inputProps={{
            autoFocus: true
          }}
        />

        {interactionId && (
          <div style={{ padding: '24px 0px'}}>
            <a href={`/v2/interaction/${interactionId}?accountDomain=eog`}>Login with EOG or Vendor Account</a>
          </div>
        )}
      </div>

      <div className={classes.buttonContainer}>
        <ProgressButton id="submit_passwordless_login" onClick={onPressSubmit} loading={loading} success={false}>
          Submit
        </ProgressButton>
      </div>
    </div>
  );
});
