/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "redux-starter-kit";

export interface OptionModel {
  key: string;
  type: string;
  text: string;
  buttonText: string;
  nextStep: string;
}

export interface PressSubmitCodeNotOkPayload {
  statusText: string;
  status: number;
}

export interface FormModel {
  options: OptionModel[] | null;
  optionsError: Error | null;
  selectedIndex: number;
  busy: boolean;
  reason?: string;
  reasonDescription?: string;
  nextStepFailedMessage?: string;
  claims: Record<string, any>;
}

export type ChangeValuesPayload = Partial<FormModel>;

export interface FailedInitModel {
  statusText: string;
  status: number;
}

export interface OptionModel {
  type: string;
  key: string;
  text: string;
  nextStep: string;
  buttonText: string;
}
export interface OptionsReceivedPayload {
  options: OptionModel[];
  reason: string;
  reasonDescription: string;
  claims: Record<string, any>;
}

export interface OtpModel {
  code: string | null;
  busy: boolean;
  errorMessage?: string;
  reason?: string;
}

export type ChangeOtpValuesPayload = Partial<OtpModel>;

export interface PressOptionPayload {
  value: string;
}
export interface ErrorPayload {
  error: Error;
}
export interface NextStepFailedPayload {
  message: string;
}
export const initialState = {
  form: {
    options: null,
    optionsError: null,
    selectedIndex: 0,
    busy: false,
  } as FormModel,
  otp: {
    code: null,
    attempts: 0,
    message: "",
    busy: false,
  } as OtpModel,
};
export const multiFactorSlice = createSlice({
  slice: "multiFactor",
  initialState,
  reducers: {
    multiFactorShown(draft) {
      draft.form.options = null;
      draft.form.busy = false;
    },
    getOptionsFailed(draft, action: PayloadAction<ErrorPayload>) {
      draft.form.optionsError = action.payload.error;
    },
    pressOption(draft, action: PayloadAction<PressOptionPayload>) {
      if (!draft.form.options) return;
      const selectedIndex = draft.form.options.findIndex(
        (x) => x.key === action.payload.value
      );
      draft.form.selectedIndex = selectedIndex;
    },
    changeValues(draft, action: PayloadAction<ChangeValuesPayload>) {
      draft.form = {
        ...draft.form,
        ...action.payload,
      };
    },
    changeOtpValues(draft, action: PayloadAction<ChangeOtpValuesPayload>) {
      draft.otp = {
        ...draft.otp,
        ...action.payload,
      };
    },
    onOptionsReceived(draft, action: PayloadAction<OptionsReceivedPayload>) {
      const { options } = action.payload;

      const optionKeys = options.map((option) => option.key);

      if (optionKeys.includes("hypr_push")) {
        const index = options.findIndex((option) => option.key === "hypr_push");
        draft.form.selectedIndex = index;
      }

      draft.form.options = options;
      draft.form.reason = action.payload.reason;
      draft.form.reasonDescription = action.payload.reasonDescription;
      draft.form.claims = action.payload.claims;
    },
    resendMfaCode(draft) {
      draft.form.busy = true;
      delete draft.form.nextStepFailedMessage;
    },
    sendMfaCode(draft) {
      draft.form.busy = true;
      delete draft.form.nextStepFailedMessage;
    },
    pressNextStep(draft) {
      draft.form.busy = true;
      delete draft.form.nextStepFailedMessage;
    },
    pressNextStepFailed(draft, action: PayloadAction<NextStepFailedPayload>) {
      draft.form.busy = false;
      draft.form.nextStepFailedMessage = action.payload.message;
    },
    pressNextStepSucceeded() {
      /* NADA SINCE IT WILL NAVIGATE AWAY */
    },
    pressCancel(draft) {
      draft.form.busy = false;
    },
    codeEntryShown(draft) {
      draft.otp = initialState.otp;
    },
    pressSubmitCode(draft) {
      draft.otp.busy = true;
    },
    pressSubmitCodeOk() {},
    pressSubmitCodeNotOk(
      draft,
      action: PayloadAction<PressSubmitCodeNotOkPayload>
    ) {
      draft.otp.busy = false;
      draft.otp.errorMessage = action.payload.statusText;
    },

    magicLinkSent(draft) {
      draft.form.busy = false;
    },
    waitForMagicLinkShown(draft) {
      draft.otp = initialState.otp;
    },
    resetUser(draft) {},
  },
});
