import React, { useEffect, Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { passwordlessSlice } from './slice';
import { IState } from 'store/reducers';
import { Error } from './Error';
import { SMSEntry } from './SMSEntry';
import { CodeVerify } from './CodeVerify';
import SwipeableViews from 'react-swipeable-views';
import { PUBLIC_URL } from '../../env';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography/Typography';

import { InteractionHeader } from '../Anonymous/Header';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: theme.typography.pxToRem(320),
    maxWidth: theme.typography.pxToRem(400),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.down(325)]: {
      margin: 0,
      padding: theme.spacing(1),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerBrand: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginRight: 7,
    height: 40,
    width: 40,
  },
  message: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
  },
  paper: {
    overflow: 'visible',
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
  errorContainer: {
    position: 'absolute',
    bottom: '10px',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const blah = {};

export const Passwordless = React.memo(() => {
  const title = useSelector(
    (state: IState) => state.passwordless.interaction && state.passwordless.interaction.clientName
  );

  const logoUri = useSelector(
    (state: IState) => state.passwordless.interaction && state.passwordless.interaction.logoUri
  );

  const classes = useStyles(blah);

  const hasError = useSelector(
    (state: IState) => !!(state.passwordless.interaction && state.passwordless.interaction.error)
  );

  const verify = useSelector((state: IState) => !!(state.passwordless.verify && state.passwordless.verify.message));
  const verificationErrorMessage = useSelector((state: IState) => {
    if (state.passwordless.verify) {
      return state.passwordless.verify.error_message;
    }
  });

  const { interactionId } = useParams<any>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!interactionId) return;

    const action = passwordlessSlice.actions.startInteraction({ interactionId });
    dispatch(action);
  }, [interactionId, dispatch]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const index = verify ? 1 : 0;
  let view = (
    <SwipeableViews index={index} disabled>
      <SMSEntry />
      <CodeVerify />
    </SwipeableViews>
  );
  if (hasError) {
    view = <Error />;
  }

  return (
    <Fragment>
      <Dialog
        open={true}
        fullScreen={fullScreen}
        classes={{
          paper: classes.paper,
        }}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.container}>
          <InteractionHeader clientName={title as string} logoUri={logoUri as string} />
          {/* <div className={classes.header}>
            <div className={classes.headerBrand}>
              <img src={`${PUBLIC_URL}/logo.png`} className={classes.logo} alt="logo" />
              <Typography id="title" className={classes.title}>
                {title}
              </Typography>
            </div>
          </div> */}
          {view}
        </div>
        {fullScreen && verificationErrorMessage && (
          <div className={classes.errorContainer}>
            <Alert id="passwordless-error" variant="filled" severity="error">
              {verificationErrorMessage}
            </Alert>
          </div>
        )}
      </Dialog>
      {!fullScreen && verificationErrorMessage && (
        <div className={classes.errorContainer}>
          <Alert id="passwordless-error" variant="filled" severity="error">
            {verificationErrorMessage}
          </Alert>
        </div>
      )}
    </Fragment>
  );
});
