import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { OptionModel } from './slices';
import { UxNoMfaOptionsFound } from './UxNoMfaOptionsFound';
import { ProgressButton } from '../../ProgressButton/ProgressButton';
import { Dialog } from '../Dialog/Dialog';
import { UxAlert } from './UxAlert';
import { MFAOptions } from './MFAOptions';
import ArrowForward from '@material-ui/icons/ArrowForward';

import { InteractionHeader } from '../Header';
export interface UxMultiFactorProps {
  busy: boolean;
  options: OptionModel[] | null;
  message: string;
  title?: string;
  selectedIndex: number;
  errorMessage?: string;
  onChange: (value: string) => void;
  onPressNextStep: () => void;
  onPressCancel: () => void;
  logoUri?: string;
  clientName: string;
  resetUserMessage: string;
  onUserReset: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: theme.typography.pxToRem(360),
    maxWidth: theme.typography.pxToRem(400),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.down(325)]: {
      margin: 0,
      padding: theme.spacing(1),
    },
  },
  nextStep: {
    height: '2rem',
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1)
  },
  other: {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(4)
  },
  cancelButton: {
    color: 'red',
    marginRight: '10px'
  },
  icon: {
    height: '80px',
    width: '80px',
  },
  logo: {
    height: '60px',
    width: '60px',
  },
  primaryButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}));

export const UxMultiFactor = (props: UxMultiFactorProps): JSX.Element | null => {
  const {
    errorMessage,
    options,
    message,
    title,
    selectedIndex,
    onChange,
    onPressNextStep,
    onPressCancel,
    busy,
    logoUri,
    clientName,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  if (!options) return null;
  if (options.length === 0) return <UxNoMfaOptionsFound />;

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        id="multi-factor-options-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.container,
        }}
      >
        <InteractionHeader clientName={clientName} logoUri={logoUri} />

        <Typography variant="h6" id="alert-dialog-title">{title}</Typography>
        <MFAOptions 
          message={message}
          options={options}
          onChange={onChange}
          selectedIndex={selectedIndex}
        />
        <div className={classes.buttonContainer}>
          <Button className={classes.cancelButton} id="multi-factor-cancel" onClick={onPressCancel}>
            Cancel
          </Button>
          <ProgressButton
            id="multi-factor-next-step"
            color="primary"
            onClick={onPressNextStep}
            loading={busy}
            success={false}
          >
            {options[selectedIndex].buttonText}
          </ProgressButton>
          </div>
        <UxAlert message={errorMessage} action={<Button onClick={onPressNextStep}>Retry</Button>} />
      </Dialog>
    </>
  );
};
