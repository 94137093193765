import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { SessionWall } from './components/SessionWall/SessionWall';
import Theme from './Theme';
import PalaPala from './components/PalaPala/PalaPala';
import { Route } from 'react-router-dom';
import { history } from './store/reducers';
import { Splash } from 'components/SessionWall/Splash'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from 'components/Anonymous/Login/Login';
import { MultiFactor } from 'components/Anonymous/MultiFactor/MultiFactor';
import { DuoPush } from 'components/Anonymous/Duo/DuoPush';
import { HyprPush } from 'components/Anonymous/Hypr/HyprPush';
import { NewDevice } from 'components/Anonymous/Device/Device';
import { CodeEntry } from 'components/Anonymous/MultiFactor/CodeEntry';
import { TOTPCodeEntry } from 'components/Anonymous/MultiFactor/TOTPCodeEntry';
import { Passwordless } from './components/Passwordless/Passwordless';
import { WaitForMagicLink } from 'components/Anonymous/WaitForMagicLink/WaitForMagicLink';
import { InteractionWall } from 'components/Anonymous/InteractionWall2';
import { Approve } from 'components/Approve/Approve';
import { supportedBrowser, UnsupportedBrowser } from 'components/UnsupportedBrowser';

import { InteractionWall as V3InteractionWall } from 'v3/InteractionWall';
import { ReauthInteractionWall } from './components/Reauth/views/InteractionWall';

toast.configure();

const MySecureComponent = React.lazy(() => import('components/MySecureComponent/MySecureComponent'));
const App: React.FC = () => {
  const isSupportedBrowser = supportedBrowser();

  if (!isSupportedBrowser) {
    return <UnsupportedBrowser />;
  }

  return (
    <Theme>
      <ConnectedRouter history={history}>
        <Route path="/test">
          <div>Test</div>
        </Route>
        <Route path={`/session`}>
          <Suspense fallback={<Splash />}>
            <SessionWall>
              {process.env.NODE_ENV === 'production' && <PalaPala />}
              <Route exact path={`/session/`} component={MySecureComponent} />
            </SessionWall>
          </Suspense>
        </Route>
        <Route exact path={`/magic_link/:code/approve`} render={() => <Approve />} />
        <Route path={`/v2/interaction/:interactionId`}>
          <Suspense fallback={<Splash />}>
            <InteractionWall>
              <Route exact path={`/v2/interaction/:interactionId/device`} render={() => <NewDevice />} />
              <Route exact path={`/v2/interaction/:interactionId`} render={() => <Login />} />
              <Route exact path={`/v2/interaction/:interactionId/mfa/options`} render={() => <MultiFactor />} />
              <Route exact path={`/v2/interaction/:interactionId/mfa/duo_push`} render={() => <DuoPush />} />
              <Route exact path={`/v2/interaction/:interactionId/mfa/hypr_push`} render={() => <HyprPush />} />
              <Route exact path={`/v2/interaction/:interactionId/mfa/magic_link`} render={() => <WaitForMagicLink />} />
              <Route exact path={`/v2/interaction/:interactionId/mfa/code_entry`} render={() => <CodeEntry />} />
              <Route exact path={`/v2/interaction/:interactionId/mfa/totp`} render={() => <TOTPCodeEntry />} />
            </InteractionWall>
          </Suspense>
        </Route>
        <Route exact path={'/passwordless/1/:interactionId'} component={React.memo(Passwordless)} />
        <Route path="/v3/interaction/:interactionId">
          <V3InteractionWall />
        </Route>
        <Route path="/v2/reauth/interaction/:interactionId">
          <ReauthInteractionWall />
        </Route>
      </ConnectedRouter>
    </Theme>
  );
};

export default App;
